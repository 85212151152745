import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClock, faEnvelope, faLocationDot, faPhone, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLine, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import logo_desc from '../../assets/vibeep.png'

const img3 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/trang-chu-Vibe-04.png'

function Footer() {
    return (
        <div className='bg-gray-100'>
            <div className='h-2 bg-secondary'></div>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div className='mt-10 block sm:flex '>
                    <div className='w-full sm:w-1/4 sm:pe-5'>
                        <div className='w-4/5'>
                            <img src={logo_desc} alt="VIBE" />
                        </div>
                        <div className='mt-3 alt-font hidden'>
                            <span className='text-color-primary font-semibold block w-5/6' style={{ lineHeight: "30px" }}>
                                VIETNAM INTERIOR & BUILD EXHIBITION</span>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 px-10'>
                        <p className='text-2xl font-semibold'>TRIỂN LÃM NỘI THẤT VÀ XÂY DỰNG VIỆT NAM - VIBE 2024</p>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faClock} /> 02-05/10/2024</h2>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faLocationDot} /> SECC - 799 Nguyễn Văn Linh, P. Tân Phú, Quận 7, TP. HCM</h2>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faPhone} /> <a href='tel:0966965747'>+84 96 696 5747</a></h2>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faEnvelope} /> info@thevibexpo.com</h2>
                        <h2 className='mt-3 hidden'><FontAwesomeIcon icon={faLocationDot} /> 41-45 Đường số 7, Khu đô thị Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam</h2>
                        <h2 className='mt-3 hidden'><FontAwesomeIcon icon={faReceipt} /> 0317075533</h2>
                    </div>
                    <div className='w-full md:w-1/4 text-color-primary text-right'>
                        <img src={img3} className='w-full object-cover' />
                        {/* <p className='text-xl font-semibold'>Theo dõi VIBE tại</p>
                        <div className='w-full text-2xl mt-4'>
                            <a className='social-link mr-2' href='https://www.facebook.com/Vibexpo'>
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a className='social-link mr-2' href='https://www.instagram.com/vibe_expo_vietnam/'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a className='social-link mr-2' href='https://www.linkedin.com/company/vibe-vietnam-interior-build-expo/'>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a className='social-link mr-2' href='/'>
                                <FontAwesomeIcon icon={faLine} />
                            </a>
                            <a className='social-link' href='/'>
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className='hidden mx-auto max-w-7xl py-10 mt-10 text-color-primary font-semibold border-t-2 border-black border-solid' >
                    {/* <span>&copy; Copyright 2024 - All rights reserved by VIBE</span> */}
                    <div className='block sm:flex'>
                        <div className='block w-full sm:w-1/5'></div>
                        <div className='block w-full sm:w-3/5'>
                            <div className='w-full text-center'>
                                <p>Website được dựng bởi: <span className='font-normal'>CÔNG TY CỔ PHẦN PHÁT TRIỂN HAWA</span> </p>
                                {/* <a className='social-link mr-2' href='/'>
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a className='social-link mr-2' href='/'>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a className='social-link mr-2' href='/'>
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                                <a className='social-link' href='/'>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </a> */}
                            </div>
                        </div>
                        <div className='block w-full sm:w-1/5'>
                            <div className='text-right text-color-primary'>
                                <a className="inline-block mx-3">
                                    <span>Terms</span>
                                </a>
                                <a className="inline-block mx-3">
                                    <span>Privacy</span>
                                </a>
                                <a className="inline-block mx-3">
                                    <span>Cookies</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-10 mt-5 bg-secondary'></div>
        </div>
    )
}

export default Footer