import React, { useEffect, useLayoutEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import CountDownTime from './count-down-time';
import SliderBanner from './slider-banner';
import FeaturedExhibitors from './featured-exhibitors';
import SliderGallery from './slider-gallery';
import Events from './events';
import Testimonials from './testimonial';
import EndorsedBy from './endorsed-by';
import Organizer from './organizers';
import Partner from './partner';
import AdsPopup from '../components/controls/ads-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faArrowRightLong, faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import exhibitorApi from '../api/exhibitorApi';
import newsApi from '../api/newsApi';
import SliderExhibitor from './slider-exhibitor';
import CommonHelper from '../helpers/common';
import HomeNews from './news';
import eventApi from '../api/eventApi';

const img1 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/trang-chu-Vibe-02.png'
const img3 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/trang-chu-Vibe-04.png'
const fact_feature = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/fact-feature.png'

function Home() {
    const [{ ListFeaturedExhibitors, ListNews, ListEvent, flag_run }, setCommonState] = useState({
        ListFeaturedExhibitors: [],
        ListNews: [],
        ListEvent: [],
        flag_run: true
    })
    const [{ show_ads }, setShow] = useState({
        show_ads: false
    })
    /*const get_banner = () => {
        const response = onlineApi.get_banner();
        response.then((res) => {
            console.log(res);
        })
    }*/
    var flag = true;
    useLayoutEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (window.pageYOffset >= 365) {
                if (flag) {
                    run_random()
                    flag = false;
                }
            }
        })
    }, [])
    useEffect(() => {
        get_featured_exhibitor();
        get_news();
        get_events();
    }, [])
    const run_random = () => {
        const obj_booth = document.getElementById("count-boot");
        const obj_visitor = document.getElementById("count-visitor");
        const obj_exhibitor = document.getElementById("count-exhibitor");
        const obj_meeting = document.getElementById("count-meeting");
        const obj_space = document.getElementById("count-space");
        const obj_event = document.getElementById("count-event");
        animateValue(obj_booth, 300, 500, 3000);
        animateValue(obj_visitor, 11000, 12000, 3000);
        animateValue(obj_exhibitor, 50, 150, 3000);
        animateValue(obj_meeting, 100, 300, 3000);
        animateValue(obj_space, 9000, 10000, 3000);
        //animateValue(obj_event, 0, 10, 3000);
    }
    const get_featured_exhibitor = () => {
        const response = exhibitorApi.get_exhibitor_in_home()
        response.then((res) => {
            if (res != null && res.code == 200) {
                var _data = res.data;
                _data.forEach(e => {
                    if (CommonHelper.is_not_empty(e.extraValue3)) {
                        e["obj_position"] = JSON.parse(e.extraValue3)
                    }
                })
                setCommonState((prev) => ({ ...prev, ListFeaturedExhibitors: _data }));
            }
        })
    }
    const get_news = () => {
        const response = newsApi.get_news_in_home();
        response.then((res) => {
            if (res != null && res.code == 200) {
                var ListNews = res.data
                setCommonState((prev) => ({ ...prev, ListNews }));
            }
        })
    }
    const get_events = () => {
        const response = eventApi.get_event_in_home();
        response.then((res) => {
            if (res != null && res.code == 200) {
                var ListEvent = res.data
                setCommonState((prev) => ({ ...prev, ListEvent }));
            }
        })
    }
    function animateValue(obj, start, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            var _val = Math.floor(progress * (end - start) + start)
            obj.innerHTML = _val;
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }
    function format_number(val) {
        if (val > 9999) {
            var str = val + "";
            str = str.substring(0, 2) + "." + str.substring(2);
        }
    }

    return (
        <>
            <div className='bg-backdrop relative'>
                <div className='relative pt-72 lg:pt-0 lg:px-8'>
                    <div className='px-2'>
                        <div className='sm:px-5 sm:py-5'>
                            <h2 className='text-3xl sm:text-4xl font-bold text-white'>TRIỂN LÃM</h2>
                            <h2 className='text-4xl sm:text-7xl md:text-5xl font-bold text-black'>NỘI THẤT</h2>
                            <h2 className='text-4xl sm:text-7xl md:text-5xl font-bold text-black'>& XÂY DỰNG <span className='text-white text-2xl'>VIỆT NAM</span></h2>
                            <h2 className='text-xl sm:text-2xl font-thin text-white'>VIETNAM INTERIOR & BUILD EXPO 2024</h2>
                        </div>
                        <div className='mt-4 sm:mt-8 flex sm:block'>
                            <a target='_blank' href='/floorplan' className='bg-secondary px-2 lg:px-5 py-2 rounded-lg inline-block text-white font-bold border-2 border-white'>ĐĂNG KÝ GIAN HÀNG</a>
                            <a href='/register' className='bg-primary sm:ml-5 px-2 lg:px-5 py-2 rounded-lg inline-block text-white font-bold border-2 border-white'>ĐĂNG KÝ THAM QUAN</a>
                        </div>
                        <div className='mt-5 lg:mt-14'>
                            <div className='block lg:flex items-center'>
                                <div className='w-full lg:w-1/2'>
                                    <div className='hidden lg:block'>
                                        <img src={img1} className='object-cover w-full lg:w-2/3' />
                                    </div>
                                    <div className='lg:hidden'>
                                        <img src={img3} className='object-cover w-full lg:w-2/3' />
                                    </div>
                                </div>
                                <div className='w-full mt-5 lg:w-1/2 lg:mt-0'>
                                    <CountDownTime />
                                </div>
                            </div>
                        </div>
                        <div className='h-5'></div>
                    </div>
                </div>
            </div>

            <div className='mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 relative'>
                <div className=''>
                    <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">Về Vibe</h2>
                    <p className='text-xl font-semibold text-color-primary text-center'>Vibe - Sân chơi toàn diện cho chuỗi cung ứng ngành Nội thất & Xây dựng tại Việt Nam</p>
                    <div className='mt-10 block lg:flex'>
                        <div className='w-full hidden lg:w-1/4 lg:flex items-center'>
                            <p className='leading-normal text-xl text-color-primary'>VIBE mang đến một Nền tảng kết nối & lan tỏa những giá trị và cơ hội trong chuỗi cung ứng ngành Nội thất & Xây dựng đáp ứng các tiêu chí Style (Phong cách) – Smart (Công nghệ) - Sustainability (Bền vững) dành cho thị trường nội địa và dự án.</p>
                        </div>
                        <div className='w-full lg:w-2/3'>
                            <div className='lg:ml-20 flex'>
                                <div className='w-1/4 lg:w-1/3 pl-2 lg:pl-10 py-5 box-border'>
                                    <p className='fs-color-secondary text-4xl lg:text-6xl font-bold' id="count-boot">500</p>
                                    <span className='inline-block text-color-primary'>Booths</span>
                                </div>
                                <div className='w-1/2 bg-gray-200 pl-10 py-5 box-border'>
                                    <p className='text-color-primary text-4xl lg:text-6xl font-bold' id="count-visitor">12.000</p>
                                    <span className='inline-block text-color-primary'>Visitors</span>
                                </div>
                                <div className='w-1/4 lg:w-1/3 pl-10 py-5 box-border'>
                                    <p className='text-color-primary text-6xl font-bold' ></p>
                                    <span className='inline-block text-color-primary'></span>
                                </div>
                            </div>
                            <div className='lg:ml-20 flex'>
                                <div className='w-1/4 lg:w-1/3 bg-gray-200 pl-10 py-5 box-border'>
                                    <p className='fs-color-secondary text-6xl font-bold' ></p>
                                    <span className='inline-block text-color-primary'></span>
                                </div>
                                <div className='w-1/2 pl-10 py-5 box-border'>
                                    <p className='fs-color-secondary text-4xl lg:text-6xl font-bold' id="count-exhibitor">150</p>
                                    <span className='inline-block text-color-primary'>Exhibitors</span>
                                </div>
                                <div className='w-1/4 lg:w-1/3 bg-gray-200 pl-2 lg:pl-10 py-5 box-border'>
                                    <p className='text-color-primary text-4xl lg:text-6xl font-bold' id="count-meeting">300</p>
                                    <span className='inline-block text-color-primary'>Business meetings</span>
                                </div>
                            </div>
                            <div className='lg:ml-20 flex'>
                                <div className='w-1/4 lg:w-1/3 flex items-center lg:pl-10 box-border'>
                                    <img src={fact_feature} className='object-cover w-full lg:w-5/6 mx-auto' />
                                </div>
                                <div className='w-1/2 bg-gray-200 pl-10 py-5 box-border'>
                                    <p className='text-color-primary text-4xl lg:text-6xl font-bold' id="count-space">10.000</p>
                                    <span className='inline-block text-color-primary'>m<sup>2</sup> of exhibition space</span>
                                </div>
                                <div className='w-1/4 lg:w-1/3 pl-2 lg:pl-10 py-5 box-border overflow-hidden'>
                                    <p className='fs-color-secondary text-4xl lg:text-6xl font-bold' id="count-event">10</p>
                                    <span className='inline-block text-color-primary'>specialized events</span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-5 lg:hidden'>
                            <p className='leading-normal text-xl text-color-primary'>VIBE mang đến một Nền tảng kết nối & lan tỏa những giá trị và cơ hội trong chuỗi cung ứng ngành Nội thất & Xây dựng đáp ứng các tiêu chí Style (Phong cách) – Smart (Công nghệ) - Sustainability (Bền vững) dành cho thị trường nội địa và dự án.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 relative'>
                <div className='section-box mt-5 pb-20 bg-gray-200'>
                    <h2 className="py-10 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">Sản phẩm trưng bày tại Vibe?</h2>
                    <div className='w-full xl:w-3/4 mx-auto flex'>
                        <div className='w-1/3 mr-1 lg:mr-5'>
                            <div className='mt-5 lg:mt-32 flip-card'>
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Vibe-05.jpg'} className='object-cover' />
                                    </div>
                                    <div class="flip-card-back">
                                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Vibe-02.jpg'} className='object-cover' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 mr-1 lg:mr-5'>
                            <div className='mt-0 flip-card'>
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Vibe-06.jpg'} className='object-cover' />
                                    </div>
                                    <div class="flip-card-back">
                                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Vibe-03.jpg'} className='object-cover' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 mr-1 lg:mr-5'>
                            <div className='mt-5 lg:mt-32 flip-card'>
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Vibe-07.jpg'} className='object-cover' />
                                    </div>
                                    <div class="flip-card-back">
                                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Vibe-04.jpg'} className='object-cover' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='clear-both h-24 lg:h-80'></div>
                </div>
            </div>

            <div className='hidden mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 relative'>
                <div className='section-box mt-5'>
                    <h2 className="py-10 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">Nội quy triển lãm</h2>
                    <div className='w-3/4 mx-auto flex'>
                        <div className='w-full md:w-1/2 md:mr-1 bg-secondary text-white'>
                            <div className='flex items-center py-2'>
                                <div>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Icon-Noi-Quy-01.png'} className='w-1/2 object-cover mx-auto' />
                                </div>
                                <div>
                                    <span className='block mr-2'>Khám phá không gian triển lãm đầy phong cách với hơn 500 gian hàng đa dạng</span>
                                </div>
                            </div>
                            <div className='flex items-center py-2'>
                                <div>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Icon-Noi-Quy-02.png'} className='w-1/2 object-cover mx-auto' />
                                </div>
                                <div>
                                    <span className='block mr-2'>Khám phá không gian triển lãm đầy phong cách với hơn 500 gian hàng đa dạng</span>
                                </div>
                            </div>
                            <div className='flex items-center py-2'>
                                <div>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Icon-Noi-Quy-03.png'} className='w-1/2 object-cover mx-auto' />
                                </div>
                                <div>
                                    <span className='block mr-2'>Khám phá không gian triển lãm đầy phong cách với hơn 500 gian hàng đa dạng</span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 md:ml-1 bg-primary text-white'>
                            <div className='flex items-center py-2'>
                                <div>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Icon-Noi-Quy-04.png'} className='w-1/2 object-cover mx-auto' />
                                </div>
                                <div>
                                    <span className='block mr-2'>Khám phá không gian triển lãm đầy phong cách với hơn 500 gian hàng đa dạng</span>
                                </div>
                            </div>
                            <div className='flex items-center py-2'>
                                <div>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/Icon-Noi-Quy-05.png'} className='w-1/2 object-cover mx-auto' />
                                </div>
                                <div>
                                    <span className='block mr-2'>Khám phá không gian triển lãm đầy phong cách với hơn 500 gian hàng đa dạng</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 relative'>
                <div className='section-box'>
                    <h2 className="text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">Chuyên gia cố vấn</h2>
                    <div>
                        <Testimonials />
                    </div>
                </div>
            </div>

            {/* Events 2024 */}
            <div className='mx-auto max-w-7xl  py-6 sm:px-6 lg:px-8'>
                <div className='section-box relative'>
                    <h2 className="py-2 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide">Sự kiện nổi bật</h2>
                    <a href='/events' className='absolute top-9 lg:top-5 right-5 fs-color-secondary'>Xem chi tiết <FontAwesomeIcon icon={faArrowRightLong} className='text-xs' /></a>
                    <Events list={ListEvent} />
                </div>
            </div>

            {/* Featured Exhibitors 2024 */}
            <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide">Đơn vị triển lãm tiêu biểu</h2>
                </div>
            </div>
            <div className='w-full overflow-hidden'>
                <FeaturedExhibitors list={ListFeaturedExhibitors} />
            </div>
            <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <div className='text-center my-5'>
                        <a href='/exhibitor-list' className='px-14 py-2 rounded-2xl bg-cyan-900 text-white font-semibold hover:bg-orange-500'>XEM TẤT CẢ</a>
                    </div>
                </div>
            </div>

            <div className='mx-auto max-w-7xl  py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">Cụm gian hàng đặc biệt</h2>
                    <div className='mt-5 flex flex-wrap lg:flex-shrink-0 lg:flex-nowrap'>
                        <div className='w-2/3 xl:w-2/5'>
                            <div className='flex'>
                                <div className='w-1/2 md:w-5/12 mr-2 mb-2'>
                                    <a href='#' className='bg-blue-900 hover:bg-orange-500 block h-full w-full'>
                                        <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                            <p className='text-sm lg:text-xl'>Vibe Pavilion</p>
                                        </div>
                                    </a>
                                </div>
                                <div className='w-1/2 md:w-7/12 mr-2 mb-2 h-32 overflow-hidden'>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/other-value-4.jpg"} className='object-cover' />
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='w-7/12 mr-2 mb-2 h-32 overflow-hidden'>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/banner-gian-hang-dac-biet-03.jpg"}
                                        className='object-cover xl:w-full xl:h-auto h-full' />
                                </div>
                                <div className='w-5/12 mr-2 mb-2 h-32 overflow-hidden'>
                                    <a href='#' className='bg-orange-500 hover:bg-blue-900 block h-full w-full'>
                                        <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                            <p className='h-full overflow-hidden text-sm lg:text-xl'>Top Ten Awards by kienviet Media</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 xl:w-48'>
                            <div className='mr-2 mb-2 overflow-hidden h-[264px]'>
                                <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/banner-gian-hang-dac-biet-05.jpg"}
                                    className='object-cover h-full' />
                            </div>
                        </div>
                        <div className='w-full xl:w-1/2 relative'>
                            <div className='flex'>
                                <div className='w-1/4 md:w-3/5 mr-2 mb-2 h-32'>
                                    <a href='#' className='bg-orange-500 hover:bg-blue-900 block h-full w-full'>
                                        <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                            <p className='text-sm lg:text-xl'>Consmedia Pavilion & Áhui Awards</p>
                                        </div>
                                    </a>
                                </div>
                                <div className='w-1/4 md:w-2/5 mr-2 mb-2 h-32 overflow-hidden'>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/banner-gian-hang-dac-biet-06.jpg"}
                                        className='object-cover xl:w-full xl:h-auto h-full' />
                                </div>
                                <div className='w-1/4 lg:hidden md:w-80 mr-2 h-32 overflow-hidden' >
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/other-value-3.jpg"}
                                        className='object-cover xl:w-full xl:h-auto h-full' />
                                </div>
                                <div className='w-1/4 lg:hidden mr-2 h-32'>
                                    <a href='#' className='bg-blue-900 hover:bg-orange-500 block h-full w-full'>
                                        <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                            <p className='text-sm lg:text-xl'>Hoa Mai Design Award</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='hidden lg:block'>
                                <div className='h-32 mb-2 w-36'>
                                    <a href='#' className='bg-blue-900 hover:bg-orange-500 block h-full w-full'>
                                        <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                            <p className='text-sm lg:text-xl'>Cụm gian hàng SACA</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='hidden xl:block absolute right-2 top-32 mt-2' style={{ width: "413px", height: "264px" }}>
                                <a href='#' className='bg-blue-900 hover:bg-orange-500 block h-full w-full'>
                                    <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                        <p>Pavillion kiến trúc Gallery Architecture</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='hidden lg:block md:w-80 mr-2 h-32 overflow-hidden' >
                            <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/other-value-3.jpg"} className='object-cover' />
                        </div>
                        <div className='hidden lg:block mr-2 h-32' style={{ width: "200px" }}>
                            <a href='#' className='bg-blue-900 hover:bg-orange-500 block h-full w-full'>
                                <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                    <p>Hoa Mai Design Award</p>
                                </div>
                            </a>
                        </div>
                        <div className='w-1/2 xl:w-[249px] h-32 mr-2'>
                            <a href='#' className='bg-orange-500 hover:bg-blue-900 block h-full w-full'>
                                <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                    <p className='text-sm lg:text-xl'>Cụm gian hàng Smart Tech</p>
                                </div>
                            </a>
                        </div>
                        <div className='w-1/2 lg:hidden mr-2'>
                            <a href='#' className='bg-blue-900 hover:bg-orange-500 block h-full w-full'>
                                <div className='text-white text-xl w-2/3 mx-auto flex items-center h-full'>
                                    <p className='text-sm lg:text-xl'>Pavillion kiến trúc Gallery Architecture</p>
                                </div>
                            </a>
                        </div>
                        {/* <div className='w-full md:w-1/2 h-32 overflow-hidden'>
                            <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/home-3.jpg"} className='object-cover' />
                        </div> */}
                    </div>
                </div>
            </div>

            {/*PARTNERS*/}
            <div className='mx-auto max-w-7xl mt-10 py-3 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <div className='block lg:flex'>
                        <div className='w-full'>
                            <h2 className="text-2xl sm:text-4xl text-center font-bold text-color-primary">Đơn vị Tổ chức & Đối tác</h2>
                            <div className='mt-10 flex flex-wrap items-center'>
                                <div className='w-full lg:w-1/4 font-semibold lg:font-bold text-color-primary text-xl text-center lg:text-left'>Đơn vị đồng Tổ chức:</div>
                                <div className='w-full lg:w-1/3 mt-5 lg:mt-0'>
                                    <Partner />
                                </div>
                            </div>
                            <div className='flex flex-wrap items-center'>
                                <div className='w-full lg:w-1/4 font-semibold lg:font-bold text-color-primary text-xl text-center lg:text-left'>Đơn vị phối hợp:</div>
                                <div className='w-full lg:w-1/2 mt-5 lg:mt-0'>
                                    <Organizer />
                                </div>
                            </div>
                            <div className='mt-2 flex flex-wrap items-center'>
                                <div className='w-full lg:w-1/4 font-semibold lg:font-bold text-color-primary text-xl text-center lg:text-left'>Đơn vị truyền thông:</div>
                                <div className='w-full lg:w-3/4 mt-5 lg:mt-0'>
                                    <EndorsedBy />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tin tuc/blog */}
            <div className='mx-auto max-w-7xl mb-7 py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">Tin tức</h2>
                    <HomeNews list={ListNews} />
                    <div className='text-center my-5'>
                        <a href='/news' className='px-14 py-2 rounded-2xl text-xl bg-cyan-900 text-white font-semibold hover:bg-orange-500'>XEM THÊM</a>
                    </div>
                </div>
            </div>


            {show_ads && (
                <AdsPopup onClose={() => { setShow((prev) => ({ ...prev, show_ads: false })) }} />
            )}
        </>
    )
}

export default Home