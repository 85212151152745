import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown, faEnvelope, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons';


function MobileMenu(props) {
    const { onClose, ...other } = props
    return (
        <>
            <div className="lg:hidden fixed inset-0" id="mobile-menu" style={{ background: "#ffffffe8", zIndex: "9999" }}>
                <div className='relative w-full h-full'>
                    <div className='w-full relative h-full'>
                        <ul className='block w-full text-center text-2xl md:text-5xl font-semibold absolute top-1/2 -mt-80'>
                            <li><a href='/about' className='text-color-primary inline-block py-5 md:py-7'>Về Vibe</a></li>
                            <li><a href='/exhibitor-list' className='text-color-primary inline-block py-5 md:py-7'>Nhà triển lãm</a></li>
                            <li className='hidden'><a href='/visitor' className='text-color-primary inline-block py-5 md:py-7'>Khách tham quan</a></li>
                            <li><a href='/events' className='text-color-primary inline-block py-5 md:py-7'>Sự kiện</a></li>
                            <li><a href='/news' className='text-color-primary inline-block py-5 md:py-7'>Tin tức</a></li>
                            <li className='hidden'>
                                <a href='/register' className='bg-secondary rounded-3xl px-10 py-5 md:py-7 my-5 inline-block box-border'>
                                    <span className=' text-white'>ĐĂNG KÝ THAM QUAN</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button type="button" className="text-4xl absolute right-4 top-4" aria-controls="mobile-menu" aria-expanded="false"
                        onClick={() => { onClose(false) }}>
                        <FontAwesomeIcon icon={faTimes} className='text-gray-950' />
                    </button>
                </div>
            </div>
        </>
    )
}

export default MobileMenu